import React, { useEffect, useRef, useState } from 'react'
import { Container } from '../'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { Button } from '../../UI'

import styles from './Header.module.scss'

const LINKS = [
	{
		url: '/tours',
		text: (
			<span>
				Туристические <br /> направления
			</span>
		),
	},
	{
		url: '/jack-london-lake',
		text: (
			<span>
				Озеро Джека <br /> Лондона
			</span>
		),
	},
	// {
	//    url: '/souvenir',
	//    text: <span>Сувенирная <br /> продукция</span>
	// },
	{
		url: '/freeride',
		text: (
			<span>
				Фрирайд <br />в магадане
			</span>
		),
	},
]

function Header({ className }) {
	const [isOpen, setIsOpen] = useState(false)

	const location = useLocation()

	const headerRef = useRef(null)

	useEffect(() => {
		let prevScrollpos = window.scrollY

		window.onscroll = function () {
			const nav = headerRef.current
			const currentScrollPos = window.scrollY
			if (!nav) return
			if (prevScrollpos < currentScrollPos) {
				nav.style.transform = 'translate(0, -100%)'
			} else {
				nav.style.transform = 'translate(0, 0)'
			}
			prevScrollpos = currentScrollPos
		}
	}, [location.pathname])

	return (
		<>
			<div className={styles.header_empty}></div>

			<header
				ref={headerRef}
				className={classNames(styles.header, className)}
				style={{ transform: 'translate(0px, 0px)' }}
			>
				<Container>
					<div className={styles.wrapper}>
						<Link to='/' className={styles.menuItem}>
							<svg
								width='104'
								height='50'
								viewBox='0 0 104 50'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className={styles.logo}
							>
								<path
									d='M56.5675 7.52257C54.8554 7.65304 53.5747 8.06841 52.3206 8.90447C51.852 9.21866 51.5884 9.43433 51.173 9.84704C50.3343 10.6884 49.7539 11.639 49.3944 12.7599C48.9711 14.0913 48.9338 15.7447 49.2986 17.1399C50.0334 19.9384 52.4085 22.0418 55.4359 22.5744C56.887 22.83 58.4846 22.7527 59.8212 22.3613C61.8555 21.7676 63.5755 20.3724 64.4915 18.5751C65.008 17.5633 65.245 16.5888 65.2796 15.332C65.3408 13.1034 64.5953 11.2289 63.059 9.74053C61.8182 8.53969 60.1887 7.7915 58.3381 7.57316C58 7.53322 56.8604 7.50127 56.5675 7.52257ZM58.024 11.0186C58.3754 11.0958 58.6976 11.213 59.0251 11.3807C59.4085 11.5804 59.6854 11.7881 59.9863 12.1023C60.5854 12.7227 60.9369 13.4655 61.0673 14.3815C61.1126 14.7037 61.1206 15.4385 61.078 15.7607C60.9582 16.7432 60.5827 17.534 59.9357 18.1837C59.3899 18.7349 58.7482 19.0703 57.9521 19.2274C57.7178 19.2754 57.5633 19.286 57.1799 19.286C56.4637 19.286 55.9977 19.1848 55.4226 18.9053C54.2803 18.3461 53.5348 17.3237 53.2898 15.987C53.2206 15.6089 53.2206 14.6344 53.2898 14.2563C53.4576 13.3377 53.825 12.6215 54.4374 12.0171C54.9673 11.4952 55.7394 11.1011 56.4477 10.9973C56.5569 10.9813 56.6767 10.9627 56.7139 10.9574C56.7512 10.9494 57.0015 10.9494 57.2731 10.9547C57.6459 10.9627 57.8296 10.9787 58.024 11.0186Z'
									fill='white'
								/>
								<path
									d='M19.6504 9.43671V11.0742H26.2004H32.7505V9.43671V7.7992H26.2004H19.6504V9.43671Z'
									fill='white'
								/>
								<path
									d='M34.2998 15.1132L34.3077 22.43L36.3526 22.438L38.3949 22.4434V15.1212V7.79896H36.3446H34.2944L34.2998 15.1132Z'
									fill='white'
								/>
								<path
									d='M39.833 9.28434V10.7727L40.7596 10.7887C41.7554 10.8047 42.0084 10.8313 42.3865 10.9565C43.0015 11.1615 43.321 11.5848 43.321 12.1919C43.321 12.8736 42.9323 13.3209 42.1788 13.5126C41.8699 13.5925 41.3134 13.6297 40.4401 13.6297H39.833V15.0542V16.4787H41.0259C42.248 16.4787 42.5302 16.4947 42.895 16.5879C43.6272 16.7743 44.0399 17.2669 44.0399 17.9565C44.0399 18.6168 43.6645 19.0934 42.9882 19.2984C42.5968 19.4156 42.3465 19.4342 41.0498 19.4342H39.833L39.8383 20.9306L39.8463 22.4297L41.364 22.427C42.9243 22.4244 43.345 22.4057 44.0266 22.3072C46.1381 22.0063 47.5546 21.0584 48.0285 19.6339C48.1617 19.2292 48.2043 18.9443 48.2043 18.4224C48.2043 17.7115 48.0818 17.1657 47.8049 16.6358C47.4161 15.9036 46.66 15.2486 45.8053 14.9051C45.6828 14.8572 45.5843 14.8093 45.5843 14.8013C45.5843 14.7906 45.6828 14.7214 45.8053 14.6469C46.6919 14.101 47.2484 13.3262 47.4374 12.3703C47.4614 12.2505 47.4854 11.9842 47.4934 11.7792C47.5706 9.76894 46.1834 8.37107 43.6911 7.94771C43.0521 7.83854 42.7459 7.8199 41.2788 7.80659L39.833 7.79328V9.28434Z'
									fill='white'
								/>
								<path
									d='M72.3701 7.8176C71.5074 7.86819 70.5142 8.07321 69.774 8.35012C68.0619 8.99448 66.9809 10.1394 66.5789 11.7396C66.3738 12.5491 66.3552 13.5129 66.5256 14.365C66.8132 15.7842 67.7291 17.009 69.0418 17.7252C69.4412 17.9435 70.4716 18.2417 71.305 18.3802C72.1038 18.5133 72.3914 18.532 73.9756 18.5479L75.4587 18.5639V20.5023V22.4434H77.5222H79.5857V15.1212V7.79896H77.5222H75.4587V11.5666V15.3342H74.0901C72.3754 15.3342 72.1517 15.3075 71.6112 15.0413C71.2917 14.8815 71.0867 14.7085 70.9109 14.4475C70.6713 14.0881 70.5701 13.6514 70.5941 13.0843C70.618 12.5065 70.7645 12.1124 71.084 11.7663C71.2651 11.5719 71.4967 11.4095 71.747 11.3056C72.1704 11.1272 72.5032 11.0766 73.3366 11.0553L73.9942 11.0394V9.4205V7.79896L73.2567 7.80429C72.8493 7.80695 72.4499 7.81228 72.3701 7.8176Z'
									fill='white'
								/>
								<path
									d='M24.1499 17.4909V22.4434H26.2134H28.277V17.4909V12.5384H26.2134H24.1499V17.4909Z'
									fill='white'
								/>
								<path
									d='M67.3216 20.6647C66.7092 21.5594 66.1846 22.3235 66.158 22.3661L66.1074 22.4434H68.3174H70.5274L71.3554 21.2319C71.8107 20.5662 72.1915 20.0044 72.1995 19.9858C72.2101 19.9565 72.1622 19.9458 71.9865 19.9298C71.4699 19.8926 70.6818 19.7515 69.9869 19.5784C69.5262 19.4612 68.802 19.2243 68.5943 19.1177L68.4372 19.0379L67.3216 20.6647Z'
									fill='white'
								/>
								<path
									d='M21.0344 25.7901C20.3981 25.8114 19.6366 25.9152 19.0881 26.051C16.3402 26.7353 14.2448 28.7083 13.5365 31.2804C13.2117 32.4573 13.1744 33.8578 13.4353 35.1012C13.6643 36.1823 14.2368 37.3485 14.9743 38.2218C16.0527 39.5025 17.6742 40.4504 19.3916 40.7992C21.2022 41.1693 23.1379 41.0229 24.7168 40.3998C25.7446 39.9951 26.586 39.4466 27.3475 38.6851C28.5191 37.5189 29.1687 36.2275 29.4243 34.5714C29.5016 34.0708 29.5202 33.083 29.4643 32.5398C29.3312 31.2937 28.9584 30.234 28.3034 29.2541C27.9865 28.7775 27.7682 28.5113 27.3715 28.1145C26.1546 26.8924 24.597 26.1389 22.7545 25.8753C22.4243 25.83 21.4738 25.7634 21.3007 25.7768C21.2634 25.7794 21.1436 25.7847 21.0344 25.7901ZM22.19 29.2861C22.9622 29.4379 23.6065 29.7813 24.1497 30.3245C24.7062 30.8783 25.0576 31.544 25.2307 32.3587C25.3133 32.7581 25.3479 33.5569 25.2973 33.9989C25.1881 34.9495 24.826 35.7536 24.2216 36.3873C23.6811 36.9518 23.0154 37.3192 22.2246 37.4923C21.8598 37.5721 20.9652 37.5801 20.6004 37.5056C19.1014 37.2047 17.9804 36.1343 17.589 34.6379C17.4426 34.0868 17.4 33.3173 17.4798 32.7129C17.7275 30.8144 19.0641 29.4565 20.9013 29.2328C21.2022 29.1982 21.8811 29.2248 22.19 29.2861Z'
									fill='white'
								/>
								<path
									d='M0 30.2502V34.4385L0.0665654 34.3719C0.101179 34.3346 1.02245 33.3415 2.11412 32.1619L4.09777 30.0185L4.09244 28.0482L4.08712 26.0779L2.04489 26.0699L0 26.0645V30.2502Z'
									fill='white'
								/>
								<path
									d='M9.72388 26.1099C9.65731 26.1711 4.57704 31.6295 1.97832 34.4359L0.00266262 36.5686V38.6375L0 40.709H2.05022H4.10043V38.8771V37.0426L4.8646 36.2278C5.28263 35.7778 5.6341 35.4104 5.64209 35.4104C5.65007 35.4104 6.58465 36.5926 7.71893 38.0357C8.8532 39.4762 9.79577 40.669 9.81175 40.6824C9.83305 40.701 10.7011 40.7063 12.2321 40.7037L14.6178 40.6957L11.4785 36.6245L8.33666 32.5534L8.95705 31.8851C11.3428 29.3023 14.2983 26.0912 14.2983 26.0779C14.2983 26.0699 13.2811 26.0646 12.0377 26.0646C9.92358 26.0646 9.77447 26.0672 9.72388 26.1099Z'
									fill='white'
								/>
								<path
									d='M32.1565 33.3468C30.3885 37.3514 28.9347 40.6477 28.9268 40.669C28.9134 40.7037 29.1637 40.709 31.0222 40.709H33.1364L36.0599 33.4054C37.6655 29.3902 38.9808 26.0965 38.9808 26.0832C38.9808 26.0726 38.1687 26.0646 37.1755 26.0646H35.3703L32.1565 33.3468Z'
									fill='white'
								/>
								<path
									d='M47.44 26.0832C47.4294 26.0912 47.4214 29.3875 47.4214 33.4054V40.709H49.4982H51.5751L51.5697 33.3921L51.5618 26.0779L49.5089 26.0699C48.3799 26.0672 47.448 26.0726 47.44 26.0832Z'
									fill='white'
								/>
								<path
									d='M61.5332 33.3868V40.709H63.5967H65.6603V33.3868V26.0646H63.5967H61.5332V33.3868Z'
									fill='white'
								/>
								<path
									d='M68.5543 26.0826C68.5436 26.0906 68.5356 26.2769 68.5356 26.4953V26.892L72.3245 33.0826L76.1108 39.2705H77.096L78.0811 39.2678L79.9237 36.2538C80.9381 34.595 82.6369 31.8205 83.6993 30.0871L85.6296 26.9319V26.4979V26.0639H83.9389H82.2481L82.1842 26.1624C82.1469 26.2184 80.9914 28.1141 79.6175 30.372C78.2435 32.6326 77.1093 34.4858 77.096 34.4858C77.0853 34.4884 75.9244 32.598 74.5159 30.2842L71.9571 26.0772L70.2637 26.0693C69.3344 26.0666 68.5623 26.0719 68.5543 26.0826Z'
									fill='white'
								/>
								<path
									d='M93.1091 26.2427C93.0665 26.3439 91.6127 29.6349 89.8794 33.5596L86.7295 40.6954L88.809 40.7034C89.9513 40.7061 90.8938 40.7008 90.9045 40.6901C90.9258 40.6688 96.7276 26.2028 96.7516 26.1096C96.7649 26.067 96.669 26.0644 94.9783 26.0644H93.189L93.1091 26.2427Z'
									fill='white'
								/>
								<path
									d='M96.8847 29.6804C96.3761 30.9505 95.9608 32.0049 95.9608 32.0235C95.9608 32.0422 96.2057 32.6705 96.5066 33.4161C96.8075 34.1643 97.0524 34.7873 97.0524 34.8006C97.0524 34.8139 96.5998 34.8246 95.9395 34.8246H94.8265L94.2327 36.3077C93.9052 37.1251 93.6336 37.8094 93.6257 37.8254C93.615 37.852 94.0969 37.86 95.9501 37.8653L98.2879 37.8733L98.8604 39.2898L99.4355 40.709H101.584H103.733L103.696 40.6211C103.536 40.2457 97.8432 27.4039 97.8299 27.3906C97.8193 27.3826 97.3959 28.413 96.8847 29.6804Z'
									fill='white'
								/>
								<path
									d='M40.0004 27.4646C39.9844 27.4912 39.5638 28.543 39.0632 29.7971L38.1499 32.0789L39.8806 36.387L41.6113 40.6952L43.7574 40.7031C44.9369 40.7058 45.9034 40.7031 45.9034 40.6925C45.9034 40.6792 40.083 27.5232 40.0457 27.4513C40.0297 27.422 40.0217 27.4247 40.0004 27.4646Z'
									fill='white'
								/>
								<path
									d='M68.5356 35.2054V40.709H70.4527H72.3698V38.318V35.9296L70.4794 32.8436C69.4409 31.1449 68.5782 29.7443 68.5623 29.7283C68.5436 29.7097 68.5356 31.3872 68.5356 35.2054Z'
									fill='white'
								/>
								<path
									d='M83.7154 32.833L81.8223 35.9296V38.318V40.709H83.726H85.6298V35.224C85.6298 32.2072 85.6245 29.739 85.6192 29.739C85.6138 29.739 84.7591 31.1315 83.7154 32.833Z'
									fill='white'
								/>
								<path
									d='M53.0127 31.5627V33.1736H53.6597C54.589 33.1736 54.9644 33.2402 55.449 33.4878C55.7818 33.6582 56.0534 33.9298 56.2025 34.2413C56.3809 34.6088 56.4049 34.7419 56.4075 35.2904C56.4075 35.8362 56.3676 36.0333 56.1865 36.3821C56.0641 36.6217 55.7312 36.9625 55.4889 37.0956C55.0443 37.3406 54.5943 37.4311 53.673 37.4524L53.0127 37.4684V39.0926V40.7168L53.926 40.7008C54.5091 40.6902 54.9564 40.6715 55.1694 40.6449C56.9667 40.4159 58.29 39.8541 59.2299 38.9115C59.8476 38.2938 60.2124 37.6468 60.4307 36.7788C60.6438 35.9347 60.6597 34.8324 60.4707 33.9724C60.074 32.1618 58.8066 30.913 56.759 30.3113C55.8803 30.0557 55.0203 29.9518 53.7263 29.9518H53.0127V31.5627Z'
									fill='white'
								/>
							</svg>
						</Link>

						<div className={styles.menu}>
							{LINKS.map(link => (
								<Link
									to={link.url}
									className={classNames(
										styles.menuItem,
										location.pathname === link.url ? styles._active : ''
									)}
								>
									<span>{link.text}</span>
								</Link>
							))}
						</div>

						<div className={styles.menuMobile}>
							<div
								className={classNames({
									[styles.burger]: true,
									[styles.burgerClose]: isOpen,
								})}
								onClick={() => setIsOpen(!isOpen)}
							>
								<span></span>
							</div>
							{isOpen ? (
								<div className={styles.modal}>
									{LINKS.map(link => (
										<Link
											to={link.url}
											className={classNames(
												styles.modalMenuItem,
												location.pathname === link.url ? styles._active : ''
											)}
										>
											<span>{link.text}</span>
										</Link>
									))}
								</div>
							) : (
								<></>
							)}
						</div>

						<div className={styles.headerButton}>
							<Button
								to='mailto:tvoyakolyma@mail.ru'
								small={true}
								defaultLink={true}
							>
								Заказать тур
							</Button>
						</div>
					</div>
				</Container>
			</header>
		</>
	)
}

export { Header }
